<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="10">
                    <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{$t("message.call_history")}}
                            </div>
                        </div>
                    </el-col>

                    <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="text-right">
                        <div class="d-flex text-right">
                            <el-date-picker
                                v-model="filterForm.from_date"
                                type="date"
                                size="small"
                                :placeholder="$t('message.begin_date')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>

                            <el-date-picker
                                v-model="filterForm.to_date"
                                type="date"
                                size="small"
                                class="ml-2"
                                :placeholder="$t('message.end_date')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                            <div  class="crm-content-header-right-item">
                                <!-- <export-excel  v-can="'excel.index'" :data="list" :fields="excel_fields" @fetch="controlExcelData()" :worksheet="$t('message.call_history')" :name="$t('message.call_history')">
                                    <el-button size="mini">
                                        <i class="el-icon-document-delete"></i> {{ $t('message.download_excel') }}
                                    </el-button>
                                </export-excel> -->
                            </div>
                            <div class="crm-content-header-right-item">
                                <el-dropdown class="setting-cheek ml-2" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                                    <el-button
                                        class="padding_none"
                                        size="small"
                                        icon="el-icon-open"
                                    ></el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(column, index) in columns" :key="index">
                                            <el-checkbox :checked="column.show" @change="column.show = !column.show" >{{ column.title }}</el-checkbox>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered">
                    <thead>
                        <tr>
                            <th v-if="columns.status.show">
                                {{ columns.status.title }}
                            </th>

                            <th v-if="columns.caller_name.show">
                                {{ columns.caller_name.title }}
                            </th>

                            <th v-if="columns.caller.show">
                                {{ columns.caller.title }}
                            </th>

                            <th v-if="columns.target_name.show">
                                {{ columns.target_name.title }}
                            </th>

                            <th v-if="columns.called_to.show">
                                {{ columns.called_to.title }}
                            </th>

                            <th v-if="columns.billsec.show">
                                {{ columns.billsec.title }}
                            </th>

                            <th v-if="columns.callStatus.show">
                                {{ columns.callStatus.title }}
                            </th>

                            <th v-if="columns.calldate.show">
                                {{ columns.calldate.title }}
                            </th>

                            <th v-if="columns.recordingfile.show">
                                {{ columns.recordingfile.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.status.show">
                                <el-select clearable size="mini" v-model="filterForm.call_status" :placeholder="columns.callStatus.title">
                                    <el-option v-for="item in call_status" :key="'sta_'+item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </th>
                            <th v-if="columns.caller_name.show">

                            </th>

                            <th v-if="columns.caller.show">
                                <el-input :disabled="!(authUser.is_super_admin || (filterForm.dst.length < 1))" clearable
                                    size="mini" type="number" v-model="filterForm.src"
                                    :placeholder="columns.caller.title"></el-input>
                            </th>

                            <th v-if="columns.target_name.show">

                            </th>

                            <th v-if="columns.called_to.show">
                                <el-input :disabled="!(authUser.is_super_admin || (filterForm.src.length < 1))" clearable
                                    type="number" size="mini" v-model="filterForm.dst"
                                    :placeholder="columns.called_to.title">
                                </el-input>
                            </th>

                            <th v-if="columns.billsec.show">

                            </th>

                            <th v-if="columns.callStatus.show">
                                <el-select clearable size="mini" v-model="filterForm.disposition"
                                    :placeholder="columns.callStatus.title">
                                    <el-option v-for="item in statusOptions" :key="'stop_'+item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </th>

                            <th v-if="columns.calldate.show">
                                <el-date-picker v-model="filterForm.calldate" type="date" size="mini"
                                    :placeholder="columns.calldate.title" 
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </th>

                            <th v-if="columns.recordingfile.show">
                            </th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody" v-loading="loadingData">
                        <tr v-for="(call, index) in list" :key="'call' + index" class="cursor-pointer">
                            <td v-if="columns.status.show" style="text-align: center; vertical-align: middle;">
                                <div class="phone-status" :class="{ incoming: call.cnum.length > 5, outgoing: call.cnum.length <= 5 }">
                                    {{call.status}}
                                </div>

                            </td>
                            <td v-if="columns.caller_name.show" style="text-align: center; vertical-align: middle;">
                                {{ checkContact(call) }}
                            </td>

                            <td v-if="columns.caller.show" style="text-align: center; vertical-align: middle;">
                                <span style="display: block;">
                                    {{ call.cnum }}
                                </span>
                                <span>
                                    {{ call.cnum !== call.src ? '( ' + call.src + ' )' : '' }}
                                </span>
                            </td>

                            <td v-if="columns.caller_name.show" style="text-align: center; vertical-align: middle;">
                                <span v-html="removePrefixFromNumber(call, true)"></span>
                            </td>

                            <td v-if="columns.called_to.show" style="text-align: center; vertical-align: middle;">
                                <span v-html="removePrefixFromNumber(call, false)"></span>
                                <span style="display: block;" v-if="call.did">
                                    ( {{ call.did }} )
                                </span>
                            </td>

                            <td v-if="columns.billsec.show" style="text-align: center; vertical-align: middle;">
                                {{call.billsec}}
                            </td>

                            <td v-if="columns.callStatus.show" style="text-align: center; vertical-align: middle;">
                                <div class="phone-status"
                                    :class="{ answered: call.disposition === 'ANSWERED', cancel: call.disposition === 'NO ANSWER' || call.disposition === 'BUSY' }">
                                    {{ call.callStatus }}
                                </div> 
                            </td>

                            <td v-if="columns.calldate.show" style="text-align: center; vertical-align: middle;">
                                {{ call.calldate }}
                            </td>

                            <td v-if="columns.recordingfile.show">
                                <div v-if="call.recordingfile">
                                    <audio id="audioFromExternal" controls :src="listenToTheAudio(call)"></audio>
                                </div>
                                <div v-else>
                                    {{ $t("message.no_audio_file") }}
                                </div>
                            </td>
                        </tr>
                    </transition-group>
                </table>

                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <el-drawer
                class="right-modal"
                :with-header="false"
                :append-to-body="true"
                :visible.sync="appModal"
                size="80%"
            >
                <CrmCreate />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import callHistory from "@/utils/mixins/views/callHistory";
import list from "@/utils/mixins/list";

export default {
    name: "callHistories",
    mixins: [callHistory, list],
    components: {},

    data() {
        return {
            loadingButton: false,
            loadingData: false,
            appModal: false,
            sarcheBlocks: "",
            audioSrc: "",
            sipAccountList: [],
            // filterForm: {
            //     number_of_data: 30,
            //     src: '',
            //     dst: '',
            //     disposition: '',
            //     calldate: '',
            // },
            call_status: [
                {
                    value: 'incoming',
                    label: this.$t("message.incoming")
                },
                {
                    value: 'outgoing',
                    label: this.$t("message.outgoing")
                },
            ],
            statusOptions: [
                {
                    value: 'ANSWERED',
                    label:  this.$t("message.ANSWERED")
                },
                {
                    value: 'NO ANSWER',
                    label: this.$t("message.NO ANSWER")
                },
                {
                    value: 'BUSY',
                    label: this.$t("message.BUSY")
                },
            ],
            options: [
                {
                    value: 30,
                    label: 30
                },
                {
                    value: 100,
                    label: 100
                },
            ],
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        ...mapGetters({
            list: "callHistories/list",
            columns: "callHistories/columns",
            filter: "callHistories/filter",
            authUser: "auth/user",
            phoneContacts: "phoneContacts/list",
            permissions: "auth/permissions",
            pagination: "callHistories/pagination",
            mode: "MODE",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
        }),
    },
    watch: {
        serverSipAccountList (next, prev) {
            if(this.serverSipAccountList.length > 0){
                setTimeout(() => {
                    this.fetchData();
                }, 100);
            }
        }
    },

     methods: {
        ...mapActions({
            updateList: "callHistories/index",
            updateContactList: "phoneContacts/index",
            updatePagination: "callHistories/updatePagination",
            updateFilter: "callHistories/updateFilter",
            updateColumn: "callHistories/updateColumn",
            refreshData: "callHistories/refreshData",
        }),
        
        fetchData() {
            this.updateContactList();
            this.sipAccountList = JSON.parse(JSON.stringify(this.serverSipAccountList));
            if(this.sipAccountList){
                this.loadingButton = true;
                this.loadingData = true;
                this.updateList(this.filterForm)
                    .then(res => {
                        this.loadingButton = false;
                        this.loadingData = false;
                    })
                    .catch(err => {
                        this.loadingButton = false;
                        this.loadingData = false;
                    });
            }
        },
        
        
    },
    
};
</script>

<style lang="scss">
    .phone-status {
        border-radius: 12px;
        margin: 0 2rem;
        line-height: 26px;
        padding: 0 0.5rem;

        &.answered {
            background: #53f769;
        }

        &.cancel {
            background: #ff7272;
            color: white;
        }

        &.incoming {
            background: #bce1ff;
        }

        &.outgoing {
            background: #bcfff4;
        }
    }

    audio {
        height: 40px;
        margin: 0.4rem;
    }
    audio::-webkit-media-controls-panel {
        background-color: #dff0fd;
    }

    .incoming_call {
        color: rgb(2, 0, 128);
    }

    .outgoing_call {
        color: rgb(128, 0, 90);
    }

    .outgoing_call {
        color: rgb(128, 0, 90);
    }

    .outgoing_call {
        color: rgb(128, 0, 90);
    }

    .outgoing_call {
        color: rgb(128, 0, 90);
    }



</style>